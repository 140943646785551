import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import Cards from "react-credit-cards";
import { colors } from "../../styles";
import {
  DayClass,
  FeeType,
  Site,
  useCheckIfHasTokenMutation,
  useGetPaymentLinkMutation,
  useGetSitesQuery,
  usePayWithTokenMutation,
  useRemovePlateMutation,
  useRemoveTokenMutation,
  useValidateMfaMutation,
  useValidateRenewalQuery,
} from "../../generated/graphql";
import { ClipLoader } from "react-spinners";
import { Delete, ExpandMore } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import Countdown from "react-countdown";
import { DateTime } from "luxon";
import "react-credit-cards/es/styles-compiled.css";
import { Tooltip } from "../../shared/components/Tooltip";
import logo from "../../flag-icon.png";
import { useReadOTP } from "react-read-otp";
import { CardToken } from "../../shared/types/card-token";
import { shortEnglishHumanizer } from "../../shared/utils/humanizer";
import { isSpecialTariff } from "../../shared/utils/specialTariff";
import { isWeekend } from "../../shared/utils/date";
import { ZONE } from "../../shared/constants";

interface IParams {
  qrCode?: string;
  sessionCode?: string;
}

interface UserPlate {
  id: number;
  vrm: string
}

export const Home: React.FC = () => {
  const { qrCode, sessionCode } = useParams<IParams>();
  const [location, setLocation] = useState<string | number>(qrCode ?? "");
  const [checkedCreditCard, setCheckedCreditCard] = useState<boolean>(false);
  const [receiveEmails, setReceiveEmails] = useState<boolean>(false);
  const [payEarlyBird, setPayEarlyBird] = useState<boolean>(false);
  const [payOvernight, setPayOvernight] = useState<boolean>(false);
  const [receiveSms, setReceiveSms] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [vrm, setVrm] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [duration, setDuration] = useState<number>(0);
  const [timeSteps, setTimeSteps] = useState<number>(0);
  const { data: sites, loading: sitesLoading } = useGetSitesQuery();
  const [error, setError] = useState<string>("");
  const [requestToken, setRequestToken] = useState<boolean>(false);
  const [mfaCode, setMfaCode] = useState<string>("");
  const [step, setStep] = useState<
    "initial" | "token" | "newVrm" | "newpayment" | "paytoken"
  >("initial");
  const [tokens, setTokens] = useState<CardToken[]>([]);
  const [plates, setPlates] = useState<UserPlate[]>([]);
  const [selectedCard, setSelectedCard] = useState<string>("");
  const [selectedVrm, setSelectedVrm] = useState<string>("new");
  const [selectedSite, setSelectedSite] = useState<Site | null>(null);
  const [selectedFee, setSelectedFee] = useState<FeeType>(FeeType.Hourly);
  const [countdown, setCountdown] = useState<Date>();
  const bottomRef = useRef<null | HTMLButtonElement>(null);
  const [showDeleteCard, setShowDeleteCard] = useState<boolean>(false);
  const [showDeletePlate, setShowDeletePlate] = useState<boolean>(false);
  const [cardToDelete, setCardToDelete] = useState<string | null>(null);
  const [plateToDelete, setPlateToDelete] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [reachedMaxDuration, setReachedMaxDuration] = useState<boolean>(false);
  const [loadedBySpecialTariff, setLoadedBySpecialTariff] =
    useState<boolean>(false);
  const [specialTariffSelected, setSpecialTariffSelected] = useState<
    number | null
  >(null);
  const theme = useTheme();

  const hidePopover = () => {
    setAnchorEl(null);
    setCardToDelete(null);
    setShowDeleteCard(false);
    setShowDeletePlate(false);
  };

  useReadOTP(setMfaCode, {
    enabled: step === "token",
  });

  const [getPaymentLink, { loading: paymentLinkLoading }] =
    useGetPaymentLinkMutation();
  const [checkIfHasToken, { loading: hasTokenLoading }] =
    useCheckIfHasTokenMutation();
  const [validateMfa, { loading: validateMfaLoading }] =
    useValidateMfaMutation();
  const [payWithToken, { loading: payWithTokenLoading }] =
    usePayWithTokenMutation();
  const [removeToken, { loading: removeTokenLoading }] =
    useRemoveTokenMutation();
  const [removePlate, { loading: removePlateLoading }] =
    useRemovePlateMutation();

  const { data: session, loading: validateRenewalLoading } =
    useValidateRenewalQuery({
      variables: {
        sessionCode: sessionCode ?? "",
      },
    });

  useEffect(() => {
    if (
      session &&
      session.validateRenewal &&
      session.validateRenewal.successful
    ) {
      const { renewalInfo } = session.validateRenewal;
      if (renewalInfo) {
        const { siteId, duration, vrm, email, phone, tokens } = renewalInfo;
        const site = sites?.getSites.find((x) => x.id === siteId);
        if (site) {
          setSiteInfo(site);
        }
        setSelectedFee(FeeType.Hourly);
        setDuration(duration);
        setVrm(vrm);
        if (email) {
          setEmail(email);
          setReceiveEmails(true);
        }
        if (phone) {
          setPhone(phone);
          setReceiveSms(true);
        }
        if (tokens.length > 0) {
          setTokens(tokens);
          setSelectedCard(tokens[0].id);
          setStep("paytoken");
        } else {
          setStep("newpayment");
        }
      }
    }
  }, [session]);

  const setSiteInfo = (site: Site) => {
    setSelectedSite(site);
    setLocation(site.qrCode);
    setDuration(site.minimumTime + (site.hasFreeTime ? site.freeTime : 0));
    setTimeSteps(site.timeStep);
  };

  const handleChange = (event: SelectChangeEvent<typeof location>) => {
    setLocation(event.target.value);
    const site = sites?.getSites.find((x) => x.qrCode === event.target.value);
    if (site) {
      setSiteInfo(site);
    }
  };

  useEffect(() => {
    if (!selectedSite) {
      const site = sites?.getSites.find((x) => isSpecialTariff(x));
      if (site) {
        setLoadedBySpecialTariff(true);
        setSiteInfo(site);
      }
      if (location) {
        const site = sites?.getSites.find((x) => x.qrCode === location);
        if (site) {
          setSiteInfo(site);
        }
      }
    }
  }, [sites]);

  const handleCreditCardChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedCreditCard(event.target.checked);
    if (event.target.checked) {
      setReceiveSms(true);
    }
  };

  const handleReceiveSmsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReceiveSms(event.target.checked);
  };

  const handleReceiveEmailsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReceiveEmails(event.target.checked);
  };

  const handleEarlyBirdChecked = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPayEarlyBird(event.target.checked);
  };

  const handleOvernightChecked = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPayOvernight(event.target.checked);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleVrmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVrm(event.target.value);
  };

  const handlePhoneChange = (newPhone: string) => {
    setPhone(newPhone);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleMfaCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMfaCode(event.target.value);
  };

  const calculateAmount = (currentDuration: number) => {
    let amount = 0;
    if (selectedSite) {
      if (selectedFee === FeeType.Early) {
        amount = selectedSite.earlyBirdPrice;
      } else if (selectedFee === FeeType.Overnight) {
        amount = selectedSite.overnightPrice;
      } else if (selectedFee === FeeType.Monthly) {
        amount = selectedSite.monthlyFee;
      } else if (selectedFee === FeeType.Hourly) {
        if (reachedMaxDuration) {
          const currentTimeInAuckland = DateTime.now();
          amount = isWeekend(currentTimeInAuckland)
            ? selectedSite.maxCostWeekend
            : selectedSite.maxCost;
        } else {
          const currentTimeInAuckland = DateTime.now();
          const costPerHour = isWeekend(currentTimeInAuckland)
            ? selectedSite.costPerHourWeekend
            : selectedSite.costPerHour;
          const actualDuration = currentDuration - (selectedSite.hasFreeTime ? selectedSite.freeTime : 0);
          amount =
            Math.round(
              ((actualDuration / 60) * (costPerHour ?? 0)) *
              100
            ) / 100;
        }
      } else if (selectedFee === FeeType.SpecialTariff) {
        const pricing = selectedSite.specialTariffPricing?.find(
          (x) => x.id === specialTariffSelected
        );
        if (pricing) {
          amount = pricing.price;
        }
      }
    }
    return amount + (selectedSite?.transactionFee ?? 0);
  };

  const increaseDuration = () => {
    if (selectedSite) {
      const currentTimeInAuckland = DateTime.now();
      const newDuration = duration + timeSteps;
      if (selectedSite.hasMaxTime || selectedSite.hasMaxTimeWeekend) {
        const maxCost = isWeekend(currentTimeInAuckland)
          ? selectedSite.maxCostWeekend
          : selectedSite.maxCost;
        const maxTimeHours = isWeekend(currentTimeInAuckland)
          ? selectedSite.maxTimeWeekend
          : selectedSite.maxTime;
        const amount = calculateAmount(newDuration);
        if (amount >= maxCost) {
          setDuration(maxTimeHours * 60);
          setReachedMaxDuration(true);
          return;
        }
      }
      setDuration(newDuration);
    }
  };

  const decreaseDuration = () => {
    if (duration - timeSteps < 0) {
      return;
    }
    if (selectedSite) {
      if (reachedMaxDuration) {
        setReachedMaxDuration(false);
        setDuration(selectedSite.minimumTime);
      } else {
        setDuration(duration - timeSteps);
      }
    }
  };

  const durationText = shortEnglishHumanizer(duration * 60 * 1000, {
    units: ["h", "m"],
  });
  const [durationFirst, durationSecond] = durationText.split(",");

  let amount = calculateAmount(duration);

  const processPayment = async () => {
    if (step === "initial") {
      await processCheckIfHasToken();
    }
    if (step === "newVrm") {
      await generatePaymentLink();
    }
    if (step === "token") {
      await processValidateMfa();
    }
    if (step === "newpayment") {
      await generatePaymentLink();
    }
    if (step === "paytoken") {
      await processPayWithToken();
    }
  };

  const processCheckIfHasToken = async () => {
    if (!phone) {
      setStep("newVrm");
    } else {
      const result = await checkIfHasToken({
        variables: {
          phone,
          siteId: selectedSite?.id ?? -1,
        },
      });
      const { data } = result;
      if (data) {
        const { checkIfHasToken } = data;
        const { hasTokens, error: errorMsg } = checkIfHasToken;
        if (errorMsg) {
          setError(errorMsg);
          return;
        }
        setError("");
        if (!hasTokens) {
          setStep("newVrm");
          // await generatePaymentLink()
        } else {
          setStep("token");
          setCountdown(DateTime.now().plus({ minutes: 5 }).toJSDate());
          setRequestToken(hasTokens);
          bottomRef.current?.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  };

  const processValidateMfa = async () => {
    const result = await validateMfa({
      variables: {
        phone,
        token: mfaCode,
        siteId: selectedSite?.id ?? -1,
      },
    });
    const { data } = result;
    if (data) {
      const { validateMfa } = data;
      const {
        successful,
        tokens: cardsTokens,
        plates: userPlates,
        error: errorMsg,
      } = validateMfa;
      if (errorMsg) {
        setError(errorMsg);
        return;
      }
      if (successful) {
        setError("");
        setStep("paytoken");
        setTokens(cardsTokens);
        setPlates(userPlates);
        setSelectedCard(cardsTokens.length > 0 ? cardsTokens[0].id : "");
        setSelectedVrm(userPlates.length > 0 ? userPlates[0].vrm : "new");
        setVrm(userPlates.length > 0 ? userPlates[0].vrm : "");
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const generatePaymentLink = async () => {
    if (!vrm) {
      setError("The registration number is required.");
      return;
    }
    const result = await getPaymentLink({
      variables: {
        duration,
        name,
        email,
        phone,
        vrm,
        qrCode: selectedSite?.qrCode ?? "",
        storePayment: checkedCreditCard,
        receiveEmails,
        receiveSms,
        feeType: selectedFee,
        reachedMaxDuration,
        specialTariffPricingId: specialTariffSelected,
      },
    });
    const { data } = result;
    if (data) {
      const { getPaymentLink } = data;
      const { error: errorMsg, redirectUrl } = getPaymentLink;
      if (errorMsg) {
        setError(errorMsg);
      } else if (redirectUrl) {
        window.open(redirectUrl, "_self", "noopener,noreferrer");
      }
    } else {
      setError("There was a problem generating the payment link");
    }
  };

  const processPayWithToken = async () => {
    const result = await payWithToken({
      variables: {
        duration,
        name,
        email,
        phone,
        vrm,
        qrCode: selectedSite?.qrCode ?? "",
        storePayment: checkedCreditCard,
        receiveEmails,
        receiveSms,
        token: selectedCard,
        feeType: selectedFee,
        reachedMaxDuration,
        specialTariffPricingId: specialTariffSelected,
      },
    });
    const { data } = result;
    if (data) {
      const { payWithToken } = data;
      const { error: errorMsg, redirectUrl } = payWithToken;
      if (errorMsg) {
        setError(errorMsg);
      } else if (redirectUrl) {
        window.open(redirectUrl, "_self", "noopener,noreferrer");
      }
    } else {
      setError("There was a problem processing the payment. Please try again");
    }
  };

  const choosePaymentMethod = (method: string) => {
    if (method !== "new") {
      setSelectedCard(method);
      setStep("paytoken");
    } else {
      setSelectedCard("");
      setStep("newpayment");
    }
  };

  const chooseVrm = (plate: string) => {
    if (plate !== "new") {
      setSelectedVrm(plate);
      setVrm(plate);
    } else {
      setSelectedVrm("new");
      setVrm("");
    }
  };

  const chipsTheme: SxProps<Theme> = {
    height: "100%",
    width: "50%",
    borderColor: colors.orange,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  };

  const isChipSelected = (
    type: FeeType,
    selectedType: FeeType,
    specialTariff?: number | null
  ) => {
    if (type === selectedType) {
      if (type !== FeeType.SpecialTariff) {
        return {
          ...chipsTheme,
          backgroundColor: colors.orange,
          color: colors.white,
        };
      } else {
        if (
          type === FeeType.SpecialTariff &&
          specialTariffSelected === specialTariff
        ) {
          return {
            ...chipsTheme,
            backgroundColor: colors.orange,
            color: colors.white,
          };
        } else {
          return chipsTheme;
        }
      }
    } else {
      return chipsTheme;
    }
  };

  const changeSelectedFee = (newFee: FeeType) => {
    if (newFee === FeeType.Hourly && selectedSite) {
      setDuration(selectedSite.minimumTime + (selectedSite.hasFreeTime ? selectedSite.freeTime : 0));
    } else if (newFee === FeeType.Monthly) {
      const today = DateTime.now();
      const in4Weeks = today.plus({ weeks: 4 }).endOf('day');
      const difference = in4Weeks.diff(today, ["minutes"]);
      setDuration(Math.round(difference.minutes));
    } else if (newFee === FeeType.Early && selectedSite) {
      const today = DateTime.now();
      const limitTime = DateTime.now().endOf("day").minus({ hours: 5 }).set({ minute: 0, second: 0, millisecond: 0 });
      const difference = limitTime.diff(today, ["minutes"]);
      setDuration(difference.minutes);
    } else if (newFee === FeeType.Overnight && selectedSite) {
      const today = DateTime.now();
      const tomorrow = today.plus({ day: 1 });
      let overnightEnd = selectedSite.overnightEnd;
      // if (isSpecialTariff(selectedSite)) {
      //   overnightEnd = selectedSite.specialTariffEnd
      // }
      const limitTime = tomorrow.set({
        hour: parseInt(overnightEnd.split(":")[0]),
        minute: parseInt(overnightEnd.split(":")[1]),
      });
      const difference = limitTime.diff(today, ["minutes"]);
      setDuration(difference.minutes);
    } else if (
      newFee === FeeType.SpecialTariff &&
      selectedSite &&
      specialTariffSelected
    ) {
      const specialTariff = selectedSite.specialTariffPricing?.find(
        (x) => x.id === specialTariffSelected
      );
      if (specialTariff) {
        const today = DateTime.now().setZone(ZONE);
        if (specialTariff.untilTime) {
          const limitTime = today.set({
            hour: parseInt(specialTariff.untilTime.split(":")[0]),
            minute: parseInt(specialTariff.untilTime.split(":")[1]),
          });
          const difference = limitTime.diff(today, ["minutes"]);
          setDuration(difference.minutes * 60);
        } else if (specialTariff.sessionTime) {
          const difference = today
            .plus({ minutes: specialTariff.sessionTime })
            .diff(today, ["minutes"]);
          setDuration(difference.minutes * 60);
        } else {
          if (specialTariff.untilTime) {
            const limitTime = today.set({
              hour: parseInt(specialTariff.untilTime.split(":")[0]),
              minute: parseInt(specialTariff.untilTime.split(":")[1]),
            });
            const difference = limitTime.diff(today, ["minutes"]);
            setDuration(difference.minutes * 60);
          } else if (specialTariff.sessionTime) {
            const difference = today
              .plus({ minutes: specialTariff.sessionTime })
              .diff(today, ["minutes"]);
            setDuration(difference.minutes * 60);
          } else {
            if (
              specialTariff.end < specialTariff.start &&
              today.hour < parseInt(specialTariff.start.split(":")[0])
            ) {
              const tomorrow = today.plus({ day: 1 });
              const limitTime = tomorrow.set({
                hour: parseInt(specialTariff.end.split(":")[0]),
                minute: parseInt(specialTariff.end.split(":")[1]),
              });
              const difference = limitTime.diff(today, ["minutes"]);
              setDuration(difference.minutes * 60);
            } else {
              const limitTime = today.set({
                hour: parseInt(specialTariff.end.split(":")[0]),
                minute: parseInt(specialTariff.end.split(":")[1]),
              });
              const difference = limitTime.diff(today, ["minutes"]);
              setDuration(difference.minutes * 60);
            }
          }
        }
      }
    }
    setSelectedFee(newFee);
  };

  const markCardToDelete =
    (token: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setCardToDelete(token);
      setShowDeleteCard(true);
    };

  const markPlateToDelete =
    (id: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setPlateToDelete(id);
      setShowDeletePlate(true);
    };

  const deletePlate = async () => {
    const response = await removePlate({
      variables: {
        plateId: plateToDelete ?? -1,
      },
    });
    const { data } = response;
    const result = data?.removePlate;
    if (result) {
      const newPlates = plates.filter((x) => x.id !== plateToDelete);
      setPlates(newPlates);
      if (newPlates.length === 0) {
        chooseVrm("new");
      }
      setShowDeletePlate(false);
      setPlateToDelete(null);
    } else {
      setError("There was a problem deleting the plate");
    }
  }

  const deleteCard = async () => {
    const response = await removeToken({
      variables: {
        token: cardToDelete ?? "",
        siteId: (selectedSite?.id ?? -1).toString(),
      },
    });
    const { data } = response;
    const result = data?.removeToken;
    if (result) {
      const newTokens = tokens.filter((x) => x.id !== cardToDelete);
      setTokens(newTokens);
      if (newTokens.length === 0) {
        setStep("newpayment");
      }
      setSelectedCard("");
      setShowDeleteCard(false);
      setShowDeletePlate(false);
      setCardToDelete(null);
      setPlateToDelete(null);
    } else {
      setError("There was a problem deleting the card");
    }
  };

  const getCards = (tokens: CardToken[]) => {
    return tokens
      .map((token) => (
        <Stack alignItems={"center"}>
          <Cards
            key={token.id}
            expiry={token.cardExpiry}
            name={token.cardHolder}
            number={token.cardNumber}
            cvc={"XXXX"}
            focused={selectedCard === token.id ? "number" : undefined}
          />
          <IconButton
            sx={{ width: "fit-content" }}
            onClick={markCardToDelete(token.id)}
          >
            <Delete />
          </IconButton>
        </Stack>
      ))
      .concat([
        <>
          <Stack
            width={"100%"}
            alignItems="center"
            height={100}
            justifyContent="center"
          >
            <Typography color={colors.orange}>NEW PAYMENT METHOD</Typography>
          </Stack>
        </>,
      ]);
  };

  const getPlates = (userPlates: UserPlate[]) => {
    return userPlates
      .map((plate) => (
        <Stack
          direction={"row"}
          width={"100%"}
          alignItems="center"
          height={100}
          justifyContent="center"
        >
          <Typography
            color={"black"}
            fontSize={40}
            sx={{
              border: "1px solid black",
              width: "60%",
              height: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              width={"20%"}
              sx={{
                backgroundColor: colors.plateBlue,
                height: "100%",
                textAlign: "center",
              }}
            >
              <img src={logo} alt="Flag logo" width={"70%"} />
            </Box>
            <p style={{ width: "80%", textAlign: "center" }}>{plate.vrm}</p>
            <IconButton
              sx={{ width: "fit-content" }}
              onClick={markPlateToDelete(plate.id)}
            >
              <Delete />
            </IconButton>
          </Typography>
        </Stack>
      ))
      .concat([
        <>
          <Stack
            width={"100%"}
            alignItems="center"
            height={100}
            justifyContent="center"
          >
            <Typography color={colors.orange}>NEW VRM</Typography>
          </Stack>
        </>,
      ]);
  };

  const loadingButton =
    validateMfaLoading ||
    paymentLinkLoading ||
    hasTokenLoading ||
    payWithTokenLoading;

  const canShowEarlyBird =
    selectedSite &&
    selectedSite.hasEarlyBird &&
    DateTime.now().toFormat("HH:mm") > selectedSite.earlyBirdStart &&
    DateTime.now().toFormat("HH:mm") < selectedSite.earlyBirdEnd;

  const overnightStart = selectedSite
    ? // (isSpecialTariff(selectedSite) ?
    //   selectedSite.specialTariffStart
    //   : selectedSite.overnightStart)
    selectedSite.overnightStart
    : "";
  const overnightEnd = selectedSite
    ? // (isSpecialTariff(selectedSite) ?
    //   selectedSite.specialTariffEnd
    //   : selectedSite.overnightEnd)
    selectedSite.overnightEnd
    : "";
  const canShowOvernight =
    selectedSite &&
    selectedSite.hasOvernight &&
    DateTime.now() >
    DateTime.now().set({
      hour: parseInt(overnightStart.split(":")[0]),
      minute: parseInt(overnightStart.split(":")[1]),
    }) &&
    DateTime.now() <
    DateTime.now()
      .plus({ day: 1 })
      .set({
        hour: parseInt(overnightEnd.split(":")[0]),
        minute: parseInt(overnightEnd.split(":")[1]),
      });

  useEffect(() => {
    setReachedMaxDuration(false);
    if (selectedFee === FeeType.Early) {
      setPayEarlyBird(!!canShowEarlyBird);
      setPayOvernight(false);
    } else if (selectedFee === FeeType.Overnight) {
      setPayOvernight(!!canShowOvernight);
      setPayEarlyBird(false);
    } else {
      setPayEarlyBird(false);
      setPayOvernight(false);
    }
  }, [selectedFee]);

  const specialTariffsToRender =
    selectedSite?.specialTariffPricing?.filter((x) => {
      const timeInAuckland = DateTime.now().setZone(ZONE);
      const startLimit = timeInAuckland.set({
        hour: parseInt(x.start.split(":")[0]),
        minute: parseInt(x.start.split(":")[1]),
        second: 0,
      });
      let endLimit = timeInAuckland.set({
        hour: parseInt(x.end.split(":")[0]),
        minute: parseInt(x.end.split(":")[1]),
        second: 0,
      });
      if (x.end < x.start) {
        endLimit = endLimit.plus({ day: 1 });
      }
      return (
        ((isWeekend(timeInAuckland) && x.dayClass === DayClass.Weekend) ||
          (!isWeekend(timeInAuckland) && x.dayClass === DayClass.Weekday)) &&
        timeInAuckland > startLimit &&
        timeInAuckland < endLimit
      );
    }) ?? [];

  useEffect(() => {
    if (selectedSite) {
      if (selectedSite.hasEarlyBird && canShowEarlyBird) {
        setSelectedFee(FeeType.Early);
        setPayEarlyBird(canShowEarlyBird);
      } else if (isSpecialTariff(selectedSite)) {
        setSelectedFee(FeeType.SpecialTariff);
        if (
          selectedSite.specialTariffPricing &&
          specialTariffsToRender.length > 0
        ) {
          setSpecialTariffSelected(specialTariffsToRender[0].id);
        }
      } else if (selectedSite.hasOvernight && canShowOvernight) {
        setSelectedFee(FeeType.Overnight);
        setPayOvernight(canShowOvernight);
      } else {
        setSelectedFee(FeeType.Hourly);
      }
    }
  }, [selectedSite]);

  const getSpecialTariffText = () => {
    const variant = "body1"
    const fontWeight = "700"
    if (selectedSite && selectedSite.specialTariffPricing) {
      if (specialTariffsToRender.length === 0) {
        return <>No special rates available yet</>;
      }
      const pricing = selectedSite.specialTariffPricing.find(
        (x) => x.id === specialTariffSelected
      );
      if (pricing) {
        if (pricing.untilTime) {
          return <>Until {pricing.untilTime} tomorrow</>
        } else if (pricing.sessionTime) {
          const currentTimeInAuckland =
            DateTime.now().setZone(ZONE);
          const limitTime = currentTimeInAuckland.plus({
            hours: pricing.sessionTime,
          });
          const timeToShow = limitTime.toFormat("t");
          return (
            <>
              {pricing.sessionTime} hour parking session
              <br />
              paid until {timeToShow}
            </>
          );
        } else {
          return (
            <>
              From {pricing.start} until {pricing.end}
              {pricing.end < pricing.start ? " tomorrow" : ""}`
            </>
          );
        }
      }
    }
    return <></>;
  };

  const canBuy =
    location &&
    duration > 0 &&
    ((receiveEmails && email) || !receiveEmails) &&
    ((receiveSms && phone) || !receiveSms) &&
    ((checkedCreditCard && phone) || !checkedCreditCard) &&
    ((["initial", "token"].includes(step) && !vrm) ||
      (step !== "initial" && vrm && vrm.trim().length > 0)) && (
      !loadedBySpecialTariff || (loadedBySpecialTariff && specialTariffsToRender.length > 0 && specialTariffSelected)
    );

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="location-label">Location</InputLabel>
        <Select
          labelId="location-label"
          value={location}
          label="Location"
          variant="outlined"
          onChange={handleChange}
          disabled={sitesLoading || loadedBySpecialTariff}
          IconComponent={() => (
            <Box paddingRight={1}>
              {sitesLoading || validateRenewalLoading ? (
                <ClipLoader size={"2rem"} color={colors.orange} />
              ) : (
                <ExpandMore />
              )}
            </Box>
          )}
        >
          <MenuItem value={""}>
            <em>None</em>
          </MenuItem>
          {sites &&
            sites.getSites.map((site) => (
              <MenuItem value={site.qrCode} key={site.qrCode}>
                {site.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {location && selectedSite && !sitesLoading && (
        <>
          <Stack
            direction={"row"}
            width="100%"
            height={35}
            alignItems="center"
            spacing={2}
            justifyContent={"space-evenly"}
          >
            {loadedBySpecialTariff &&
              specialTariffsToRender.map((x) => (
                <ButtonBase
                  sx={isChipSelected(FeeType.SpecialTariff, selectedFee, x.id)}
                  onClick={() => {
                    if (
                      selectedFee !== FeeType.SpecialTariff &&
                      specialTariffSelected !== x.id
                    ) {
                      changeSelectedFee(FeeType.SpecialTariff);
                      setSpecialTariffSelected(x.id);
                    }
                  }}
                >
                  {x.name}
                </ButtonBase>
              ))}
            {!loadedBySpecialTariff && (
              <>
                <ButtonBase
                  sx={isChipSelected(FeeType.Hourly, selectedFee)}
                  onClick={() => changeSelectedFee(FeeType.Hourly)}
                >
                  Hourly Fee
                </ButtonBase>
                {selectedSite.hasMonthly && (
                  <ButtonBase
                    sx={isChipSelected(FeeType.Monthly, selectedFee)}
                    onClick={() => changeSelectedFee(FeeType.Monthly)}
                  >
                    Monthly Fee
                  </ButtonBase>
                )}
                {canShowEarlyBird && (
                  <ButtonBase
                    sx={isChipSelected(FeeType.Early, selectedFee)}
                    onClick={() => changeSelectedFee(FeeType.Early)}
                  >
                    Early Bird Fee
                  </ButtonBase>
                )}
                {canShowOvernight && (
                  <ButtonBase
                    sx={isChipSelected(FeeType.Overnight, selectedFee)}
                    onClick={() => changeSelectedFee(FeeType.Overnight)}
                  >
                    Overnight Fee
                  </ButtonBase>
                )}
              </>
            )}
          </Stack>
          {selectedFee === FeeType.Hourly && (
            <>
              <Stack
                direction={"row"}
                width={"100%"}
                justifyContent="space-evenly"
                alignItems={"center"}
              >
                <Button
                  variant="outlined"
                  onClick={decreaseDuration}
                  sx={{ borderRadius: "50%", height: "5rem", width: "5rem" }}
                  disabled={duration <= (selectedSite?.minimumTime ?? 0)}
                >
                  -
                </Button>
                <Stack alignItems={"center"}>
                  <Typography
                    fontWeight={"bold"}
                    fontSize={"2rem"}
                    color={colors.blue}
                  >
                    {durationFirst.trim()}
                  </Typography>
                  {durationSecond && (
                    <Typography color={colors.blue}>
                      {durationSecond.trim()}
                    </Typography>
                  )}
                </Stack>
                <Button
                  variant="outlined"
                  onClick={increaseDuration}
                  disabled={reachedMaxDuration}
                  sx={{ borderRadius: "50%", height: "5rem", width: "5rem" }}
                >
                  +
                </Button>
              </Stack>
              <Typography
                color={colors.blue}
                textAlign={"center"}
              >{`Increase or decrease time in intervals of ${selectedSite?.timeStep} minutes with a minimum of ${selectedSite?.minimumTime} minutes`}</Typography>
              {
                selectedSite.hasFreeTime && (
                  <Typography
                    color={colors.blue}
                    textAlign={"center"}
                  >{`Free time: ${selectedSite.freeTime} minutes`}</Typography>
                )
              }
            </>
          )}
          {selectedFee === FeeType.Monthly && (
            <Stack
              alignItems={"center"}
              height="5rem"
              justifyContent={"center"}
            >
              <Typography
                fontWeight={"bold"}
                fontSize={"2rem"}
                color={colors.blue}
              >
                4 Weeks
              </Typography>
            </Stack>
          )}
          {selectedFee === FeeType.Early && (
            <Stack
              alignItems={"center"}
              height="5rem"
              justifyContent={"center"}
            >
              <Typography
                fontWeight={"bold"}
                fontSize={"2rem"}
                color={colors.blue}
              >{`Until 6pm`}</Typography>
            </Stack>
          )}
          {selectedFee === FeeType.Overnight && (
            <Stack
              alignItems={"center"}
              height="5rem"
              justifyContent={"center"}
            >
              <Typography
                fontWeight={"bold"}
                fontSize={"2rem"}
                color={colors.blue}
                textAlign={"center"}
              >
                {/* {`Until ${isSpecialTariff(selectedSite) ? selectedSite.specialTariffEnd : selectedSite.overnightEnd} tomorrow`} */}
                {`Until ${selectedSite.overnightEnd} tomorrow`}
              </Typography>
            </Stack>
          )}
          {selectedFee === FeeType.SpecialTariff && (
            <Stack
              alignItems={"center"}
              height="5rem"
              justifyContent={"center"}
            >
              <Typography
                fontWeight={"bold"}
                sx={{
                  fontSize: {
                    xs: '1.5rem',
                    sm: '2rem'
                  },
                }
                }
                color={colors.blue}
                textAlign={"center"}
              >
                {getSpecialTariffText()}
              </Typography>
            </Stack>
          )}
          <Stack alignItems={"center"}>
            {selectedSite.transactionFee > 0 ? (
              <Typography textAlign={"center"} color={colors.blue}>
                Credit card fee of ${selectedSite.transactionFee.toFixed(2)} is
                included in the transaction
              </Typography>
            ) : (
              <Typography textAlign={"center"} color={colors.blue}>
                Credit card fee is included in the transaction
              </Typography>
            )}
            <FormGroup>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCreditCardChange}
                      checked={checkedCreditCard}
                    />
                  }
                  label="STORE / USE EXISTING CREDIT CARDS"
                />
                <Tooltip text="Using our payment provider, your credit card information will be stored to facilitate the payment process in future purchases." />
              </Stack>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleReceiveSmsChange}
                      checked={receiveSms}
                      disabled={checkedCreditCard}
                    />
                  }
                  label="RECEIVE TEXT NOTIFICATIONS"
                />
                <Tooltip text="Phone number is used to send notifications and store any information associated to your account. This information is always going to be validated by a 6 digit code sent to your phone number. When clicking on storing or using credit card information, this option must be enabled and can't be unmarked." />
              </Stack>
              {receiveSms && (
                <MuiTelInput
                  onlyCountries={["NZ", "CR"]}
                  disableDropdown={!window.location.href.includes("?test=true")}
                  label="Phone"
                  margin="normal"
                  defaultCountry="NZ"
                  value={phone}
                  inputProps={{
                    maxLength: 16,
                  }}
                  fullWidth
                  onChange={handlePhoneChange}
                />
              )}
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleReceiveEmailsChange}
                      checked={receiveEmails}
                    />
                  }
                  label="RECEIVE EMAIL NOTIFICATIONS"
                />
                <Tooltip text="Email will be stored to send email notifications when a ticket is about to expire or invoice notifications after a payment has been made." />
              </Stack>
              {canShowEarlyBird && selectedFee === FeeType.Early && (
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleEarlyBirdChecked}
                        checked={payEarlyBird}
                        disabled={true}
                      />
                    }
                    label="PAY EARLY BIRD"
                  />
                  <Tooltip text="Since the ticket is being paid within the range of early bird tickets, you can opt in to get a reduced fee for a longer period of time" />
                </Stack>
              )}
              {canShowOvernight && selectedFee === FeeType.Overnight && (
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleOvernightChecked}
                        checked={payOvernight}
                        disabled={true}
                      />
                    }
                    label="PAY OVERNIGHT"
                  />
                  <Tooltip text="Since the ticket is being paid within the range of overnight tickets, you can opt in to get a reduced fee for a longer period of time" />
                </Stack>
              )}
              {receiveEmails && (
                <>
                  <TextField
                    label="Email"
                    value={email}
                    type="email"
                    margin="normal"
                    fullWidth
                    onChange={handleEmailChange}
                  />
                </>
              )}
            </FormGroup>
          </Stack>
          {requestToken && step === "token" && (
            <>
              <Typography color={colors.blue}>
                We would like to validate your information. Please provide the
                code that has been sent to your phone via SMS before the next 5
                minutes
              </Typography>
              <Typography color={colors.orange}>
                <Countdown
                  renderer={({ minutes, seconds }) => {
                    return `${minutes.toString().padStart(2, "0")}:${seconds
                      .toString()
                      .padStart(2, "0")}`;
                  }}
                  date={countdown}
                ></Countdown>
              </Typography>
              <TextField
                label="MFA Code"
                value={mfaCode}
                fullWidth
                onChange={handleMfaCodeChange}
              />
            </>
          )}
          {tokens.length > 0 && (
            <>
              <Typography color={colors.blue}>
                Please select a payment method
              </Typography>
              <Carousel
                sx={{ width: "100%" }}
                autoPlay={false}
                swipe={true}
                fullHeightHover={true}
                navButtonsAlwaysVisible={true}
                strictIndexing={false}
                indicators={false}
                onChange={(now) => {
                  if (now === undefined) return;
                  if (now >= tokens.length) {
                    choosePaymentMethod("new");
                  } else {
                    choosePaymentMethod(tokens[now].id);
                  }
                }}
              >
                {getCards(tokens)}
              </Carousel>
            </>
          )}
          {
            <>
              {plates.length > 0 && (
                <>
                  <Typography color={colors.blue}>
                    Please select your registration number
                  </Typography>
                  <Carousel
                    sx={{ width: "100%" }}
                    autoPlay={false}
                    swipe={true}
                    height={100}
                    fullHeightHover={true}
                    navButtonsAlwaysVisible={true}
                    strictIndexing={false}
                    indicators={false}
                    onChange={(now) => {
                      if (now === undefined) return;
                      if (now >= plates.length) {
                        chooseVrm("new");
                      } else {
                        chooseVrm(plates[now].vrm);
                      }
                    }}
                  >
                    {getPlates(plates)}
                  </Carousel>
                </>
              )}
              {(step === "newVrm" ||
                (["paytoken", "newpayment"].includes(step) &&
                  selectedVrm === "new")) && (
                  <TextField
                    label="Vehicle Registration"
                    disabled={selectedVrm !== "new"}
                    value={vrm}
                    fullWidth
                    onChange={handleVrmChange}
                  />
                )}
            </>
          }
          {!receiveEmails && step === "paytoken" && (
            <>
              <TextField
                label="Email for invoice"
                value={email}
                type="email"
                fullWidth
                onChange={handleEmailChange}
              />
            </>
          )}
          {error && <Typography color={"red"}>{error}</Typography>}
          <Button
            fullWidth
            disabled={!canBuy}
            ref={bottomRef}
            sx={{ padding: 2 }}
            variant="contained"
            onClick={processPayment}
          >
            {loadingButton ? (
              <ClipLoader color="white" />
            ) : step === "token" ? (
              "VALIDATE"
            ) : loadedBySpecialTariff && !specialTariffSelected ?
              'SELECT A RATE'
              :
              (
                `PAY $${amount.toFixed(2)}`
              )}
          </Button>
        </>
      )}
      <Popover open={showDeleteCard || showDeletePlate} anchorEl={anchorEl} onClose={hidePopover}>
        <Stack spacing={1} m={1}>
          <Typography variant={"body1"}>
            Are you sure you want to delete this {showDeleteCard ? 'card' : 'plate'}?
          </Typography>
          <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
            <Button
              variant="contained"
              size="small"
              onClick={hidePopover}
              sx={{ fontSize: "1rem", height: "fit-content" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={showDeleteCard ? deleteCard : deletePlate}
              sx={{ fontSize: "1rem", height: "fit-content" }}
            >
              {removeTokenLoading || removePlateLoading ? <ClipLoader color="white" /> : "Delete"}
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
